import React from 'react';
import { Form } from 'react-bootstrap';

function CheckBoxGroup({ children, row }) {

    return (
        <>
            <div className={`d-flex pb-10px ${row ? 'flex-row' : '' }`}>
                {children}
            </div>
        </>

    );
}

export default CheckBoxGroup;
