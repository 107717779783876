import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import BorderLessInput from "../forms/shared/BorderLessInput";


const Signatures = ({ formData,fillFormData, variant = 'one' }) => {


    const variantOne = () => {
        return (
            <>
                <Row className='border-top  border-dark border-4'>
                    <Col className='border-end  border-dark border-4' xs={8}>
                        <div className='d-flex align-items-center gap-2'>
                            <div className='data-input flex-grow-1'>
                                <span className='label word-wrap'> Sworn/Affirmed before me at</span>
                            </div>
                            <div style={{ width: "100%" }}>
                                <BorderLessInput
                                    label={"municipality"}
                                    type={"text"}
                                    fileno
                                    onChange={fillFormData}
                                    update={'signature.municipality'}
                                    value={formData?.signature?.municipality}
                                    style={{ padding: "6px 0" }}
                                />
                            </div>
                        </div>
                        <div className='d-flex align-items-center gap-2'>
                            <div className='data-input flex-grow-1'>
                                <span className='label word-wrap'>in</span>
                            </div>
                            <div style={{ width: "100%" }}>
                                <BorderLessInput
                                    label={"province, state or country"}
                                    type={"text"}
                                    fileno
                                    onChange={fillFormData}
                                    update={'signature.province'}
                                    value={formData?.signature?.province}
                                    style={{ padding: "6px 0" }}
                                />
                            </div>
                        </div>
                        <div className='d-flex align-items-center gap-2'>
                            <div className='data-input '>
                                <span className='label word-wrap'>on</span>
                            </div>
                            <div style={{ width: "100%" }}>
                                <Row>
                                    <Col xs={6}>
                                        <BorderLessInput
                                            label={"date"}
                                            type={"date"}
                                            fileno
                                            onChange={fillFormData}
                                            update={'signature.date'}
                                            value={formData?.signature?.date}
                                            style={{ padding: "6px 0" }}
                                        />
                                    </Col>


                                    <Col xs={6}>
                                        <BorderLessInput
                                            label={"Commissioner for taking affidavits (type or print name below if signature is illegible.)"}
                                            type={"text"}
                                            fileno
                                            onChange={fillFormData}
                                            update={'signature.commissioner'}
                                            value={formData?.signature?.commissioner}
                                            style={{ padding: "6px 0" }}
                                        />
                                    </Col>
                                </Row>

                            </div>
                        </div>

                    </Col>
                    <Col xs={4}>
                        <div className='mt-5'>
                            <BorderLessInput
                                label={"signature"}
                                type={"text"}
                                fileno
                                onChange={fillFormData}
                                style={{ padding: "6px 0" }}
                            />
                            <div className='text-center'>
                                <span className=' small'> (This form is to be signed in front of a lawyer, justice of the peace, notary public or commissioner for taking affidavits.)</span>
                            </div>
                        </div>

                    </Col>
                </Row>
            </>
        )
    }


    return (
        <>
            {variant === 'one' ? (
                <>
                    {variantOne()}
                </>
            ) : (
                <></>
            )}
        </>
    )

}


export default Signatures;