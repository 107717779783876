import React, { useState, useEffect } from 'react';

const LawyerInfoTextarea = ({ data, onChange, update }) => {
    //   const data = [
    //     {
    //       fullLegalName: 'Michael Lee',
    //       age: 12,
    //       birthdate: '2012-06-30',
    //       muncipilityAndProvince: '',
    //       nowLivingWith: 'Mother',
    //       representedByLawyer: 'Yes',
    //       lawyerName: 'Max Goodwin',
    //       lawyerPhone: '(647) 726 - 0053',
    //       lawyerAddress: 'John Kennedy Road, Toronto, Ontario',
    //       lawyerEmail: 'Max@lawyer.com',
    //     },
    //     {
    //       fullLegalName: 'Sarah Lee',
    //       age: 12,
    //       birthdate: '2012-06-30',
    //       muncipilityAndProvince: '',
    //       nowLivingWith: 'Mother',
    //       representedByLawyer: 'Yes',
    //       lawyerName: 'Max Goodwin',
    //       lawyerPhone: '(647) 726 - 0053',
    //       lawyerAddress: 'John Kennedy Road, Toronto, Ontario',
    //       lawyerEmail: 'Max@lawyer.com',
    //     },
    //     {
    //       fullLegalName: 'Chris Lee',
    //       age: 9,
    //       birthdate: '2015-06-30',
    //       muncipilityAndProvince: '',
    //       nowLivingWith: 'Mother',
    //       representedByLawyer: 'Yes',
    //       lawyerName: 'Max Goodwin',
    //       lawyerPhone: '(647) 726 - 0053',
    //       lawyerAddress: 'John Kennedy Road, Toronto, Ontario',
    //       lawyerEmail: 'Max@lawyer.com',
    //     },
    //   ];

    // State to hold the lawyer information text
    const [lawyerText, setLawyerText] = useState('');

    useEffect(() => {
        const representedByLawyer = data?.theChildren?.filter(
          (item) => item.representedByLawyer === 'Yes'
        );
    
        // Object to keep track of unique lawyer information
        const lawyerMap = new Map();
    
        representedByLawyer?.forEach((item) => {
          // Create a normalized key based on lowercase values
          const normalizedKey = `${item.lawyerName.toLowerCase()}_${item.lawyerPhone.toLowerCase()}_${item.lawyerAddress.toLowerCase()}_${item.lawyerEmail.toLowerCase()}`;
    
          // Add to map if the normalized key does not exist yet
          if (!lawyerMap.has(normalizedKey)) {
            lawyerMap.set(normalizedKey, `${item.lawyerName},  ${item.lawyerAddress},  ${item.lawyerPhone},  ${item.lawyerEmail}`);
          }
        });
    
        // Convert the Map to a text area value
        setLawyerText([...lawyerMap.values()].join('\n'));
      }, [data]);

    return (
        <div>
            <textarea
                className='custom-input-control border-0 w-100'
                value={data?.childrenLawyer?.info ?  data?.childrenLawyer?.info : lawyerText}
                rows={5}
                cols={50}
                onChange={onChange(update)}
            />
        </div>
    );
};

export default LawyerInfoTextarea;
