import React from 'react'
import CurrencyFormat from 'react-currency-format';


const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const IncomeTable = ({ formData, fillFormData }) => {

    if (!formData || !formData.income) {
        return <div>Loading...</div>; // Render a loading state or null while waiting for data
    }

    const { client, opposingParty } = formData.income;

    const renderRows = () => {
        return Object.keys(client).map((key, index) => {
            const clientValue = client[key];
            const opposingValue = opposingParty[key];

            // Only render the row if the client has a value
            if (clientValue || opposingValue) {

                // Capitalize the first letter and format the rest
                const formattedKey = capitalizeFirstLetter(
                    key.replace(/([A-Z])/g, ' $1').trim()
                );

                return (
                    <tr key={index}>
                        <td>
                            <input
                                className='form-control small py-1'
                                value={'2024'} // Display the income source
                                readOnly
                            />
                        </td>
                        <td>
                            <CurrencyFormat
                                className='form-control'
                                disabled={false}
                                value={clientValue ? clientValue : '0'}
                                thousandSeparator={true}
                                prefix={'$ '}
                                onChange={fillFormData(`income.client.${key}`,true)}
                            />
                        </td>
                        <td>
                            <input
                                className='form-control small py-1'
                                value={formattedKey} // Format the income source
                                readOnly
                            />
                        </td>
                        <td>
                            <CurrencyFormat
                                className='form-control'
                                disabled={false}
                                value={opposingValue ? opposingValue : '0'}
                                thousandSeparator={true}
                                prefix={'$ '}
                                onChange={fillFormData(`income.opposingParty.${key}`,true)}
                            />
                        </td>
                        <td>
                            <input
                                className='form-control small py-1'
                                value={formattedKey} // Format the income source
                                // value={key} // Format the income source
                                readOnly
                            />
                        </td>
                    </tr>
                );
            }

            return null; // Do not render if both values are empty
        });
    };

    return (
        <>
            <table className='pb-40px table-income'>
                <thead>
                    <tr>
                        <th>Years</th>
                        <th>Requesting Party income</th>
                        <th>Income Sources<br /><i><small>(for example, employer, self-employment, social assistance, etc.)</small></i></th>
                        <th>Responsing party's Income</th>
                        <th>Income Sources<br /><i><small>(for example, employer, self- employment, social assistance, etc.)</small></i></th>

                    </tr>
                </thead>
                <tbody>
                    {renderRows()}
                </tbody>
            </table>
        </>
    )
}

export default IncomeTable