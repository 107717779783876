import React, { useState } from "react";
import BoldandThinText from "../forms/shared/BoldandThinText";
import BorderLessInput from "../forms/shared/BorderLessInput";
import { Col, Row } from "react-bootstrap";


const Certificates = ({ responding, formData, lawyers, fillFormData }) => {

    const laywersCertificate = () => {
        return (
            <>
                <div className='mt-4' style={{ width: "100%", border: "2px solid black" }}></div>
                <div className='sub-heading mt-20px'>
                    LAWYER’S CERTIFICATE
                </div>

                <div className='data-input'>
                    <label className='label'>My name is:</label>
                    <input
                        type='text'
                        className='custom-input-control'
                        value={formData?.lawyersCertificate?.sig1?.name}
                        onChange={fillFormData('lawyersCertificate.sig1.name')}
                    />
                </div>
                <BoldandThinText
                    thin={`and I am the responding party’s lawyer in this case. I certify that I have complied with the requirements section 7.7 of the
Divorce Act and section 33.2 of the Children’s Law Reform Act regarding reconciliation and the duty to discuss and inform.`} />

                <Row>

                    <Col xs={6}>
                        <BorderLessInput
                            label={"Date"}
                            type={"date"}
                            fileno
                            onChange={fillFormData}
                            update={'lawyersCertificate.sig1.date'}
                            value={formData?.lawyersCertificate?.sig1?.date}
                            style={{ padding: "6px 0" }}

                        />

                    </Col>
                    <Col xs={6}>
                        <BorderLessInput
                            label={"Signature of Lawyer"}
                            type={"text"}
                            fileno
                            onChange={fillFormData}
                            update={'courtName'}
                            value={""}
                            style={{ padding: "6px 0" }}

                        />

                    </Col>
                </Row>
            </>
        )
    }


    const respondingCertificate = () => {
        return (
            <>
                <div className='mt-4' style={{ width: "100%", border: "2px solid black" }}></div>
                <div className='pt-4'>
                    <p className='sub-heading pb-1'>
                        RESPONDING PARTY’S CERTIFICATE
                    </p>
                </div>
                <div className='pb-3'>
                    <span className='label'> (Your lawyer, if you are represented, must complete the lawyer's Certificate below.){' '}</span>
                </div>
                <div>
                    <span className='label'> Sections 7.1 to 7.5 of the Divorce Act and section 33.1 of the Children's Law Reform Act require you and the other party to:{' '}</span>
                </div>
                <ul className='label'>
                    <li>Exercise your decision-making responsibility, parenting time, or contact with a child in a manner that is consistent with the child's best interests;</li>
                    <li>Protect the child from conflict arising from this case, to the best of your ability;</li>
                    <li>Try to resolve your family law issues by using out-of-court dispute resolution options, if it is appropriate in your case (for more information on dispute resolution options available to you, including court-connected mediation, you can visit the <span className='text-decoration-underline' style={{ color: 'blue' }}> Ministry of the Attorney General's website</span> or <span className='text-decoration-underline' style={{ color: 'blue' }}>www.stepstojustice.ca</span> );</li>
                    <li>Provide complete, accurate, and up-to-date information in this case; and</li>
                    <li>Comply with any orders made in this case.</li>
                </ul>
                <p>I certify that I am aware of these duties under the Divorce Act and the Children's Law Reform Act.</p>

            </>
        )
    }

    return (
        <>
            {responding ? (
                <>
                    {respondingCertificate()}
                </>
            ) : lawyers ? (
                <>
                    {laywersCertificate()}
                </>
            ) : (
                <></>
            )}
        </>
    )
}

export default Certificates;