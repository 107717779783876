import React from 'react'

const FormLabel = ({ label , isBold}) => {
    return (
        <>
            <span className={`label ${isBold ? 'fw-bold' : ''}`}>
                {label}
            </span>

        </>
    )
}

export default FormLabel