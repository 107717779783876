import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import '../../../../assets/css/pages/formPages/fill-pdf.css'
import FormHeading from '../shared/FormHeading'
import { Col, Row } from 'react-bootstrap'
import BorderLessInput from '../shared/BorderLessInput'
import FormInfo from '../shared/FormInfo'
import ApplicationTable from '../shared/ApplicationTable'
import { FormInformation } from '../../../../utils/Apis/matters/CustomHook/PDFData'
import { getFileData } from '../../../../utils/Apis/matters/getFileData/getFileDataActions'
import { selectGetFileData } from '../../../../utils/Apis/matters/getFileData/getFileDataSelector'
import Loader from '../../../Loader'
import CheckBox from '../shared/CheckBox'
import FormLabel from '../shared/FormLabel'
import FormCustomInput from '../shared/FormCustomInput'
import DynamicTextArea from '../shared/TextArea'
import IncomeTable from '../../Components/Form15B/IncomeTable'
import { nestedItems, types } from '../../../../utils/matterData/emptyDataArray'
import ListItem from '../shared/ListItems'
import BoldandThinText from '../shared/BoldandThinText'
import CheckBoxGroup from '../shared/CheckBoxGroup'
import SupportTable from '../../Components/Form15B/SupportTable'
import Certificates from '../../Components/Certificates'
import Signatures from '../../Components/Signatures'

const ONTFORM15B = ({ targetRef, matterId, onFormDataSave, savedData, setCourtNumber }) => {

  const dispatch = useDispatch()

  useEffect(() => {
    let data = {
      matterId: matterId,
      file_id: savedData.file_id,
      folder_id: savedData.folder_id,
    }
    dispatch(getFileData(data))
  }, [])

  const selectFileData = useSelector(selectGetFileData);

  // const { pdfData, loading, ApplicantData,ApplicantLawyerData, RespondentData,
  //   RespondentLawyerData, AssigneeData, AssigneeLawyerData } = Form15B(matterId);

  const { documentInfo, loading } = FormInformation(matterId);

  useEffect(() => {
    if (selectFileData && selectFileData[0]) {
      setFormData(JSON.parse(selectFileData[0].file_data))
    }
    else if (documentInfo) {
      setCourtNumber(documentInfo.court_info.courtFileNumber)
      setFormData(documentInfo);
    }
  }, [loading, formData, selectFileData])

  const [formData, setFormData] = useState()
  

  const stringToFloat = (value) => {
    // Remove the prefix (e.g., $) and commas, then convert to float
    const numericString = value.replace(/[^\d.-]/g, ''); // Removes everything except digits, dot, and minus
    return parseFloat(numericString) || 0; // Convert to float, fallback to 0 if NaN
  };

  function fillFormData(key, defaultVal = null) {
    return e => {
      const updatedFormData = { ...formData } // Create a shallow copy of formData

      // Split the key into an array using dot (.) separator
      const keys = key.split('.') // ['familyHistory', 'applicant', 'age']
      // const value = e.target.value
      const value = e.target.value;
      if (e.target.name === "court-number") {
        setCourtNumber(formData?.court_info?.courtFileNumber)
      }

      // Traverse the nested structure and update the value
      let nestedObj = updatedFormData
      for (let i = 0; i < keys.length; i++) {
        const k = keys[i]
        if (i === keys.length - 1) {
          // nestedObj[k] = value; // Update the value at the last key
          nestedObj[k] = defaultVal ? stringToFloat(value) : value
        } else {
          nestedObj[k] = { ...nestedObj[k] } // Create a shallow copy of the nested object
          nestedObj = nestedObj[k] // Move to the next level of nested object
        }
      }

      setFormData(updatedFormData) // Update the state with the new formData
    }
  }

  const handleChildrenDataChange = (e, index) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      familyHistory: {
        ...prevState.familyHistory,
        theChildren: prevState.familyHistory.theChildren.map((child, i) => {
          if (i === index) {
            return {
              ...child,
              [name]: value,
            };
          }
          return child;
        }),
      },
    }));
  }

  useEffect(() => {
    onFormDataSave({
      form_id: 'FORM15B',
      data: formData,
    })
  }, [formData])

  function changeFormCheck(key) {
    return e => {
      const updatedFormData = { ...formData } // Create a shallow copy of formData
      const value = e.target.checked
      

      // Split the key into an array using dot (.) separator
      const keys = key.split('.') // ['familyHistory', 'applicant', 'age']

      // Traverse the nested structure and update the value
      let nestedObj = updatedFormData
      for (let i = 0; i < keys.length; i++) {
        const k = keys[i]
        if (i === keys.length - 1) {
          nestedObj[k] = value // Update the value at the last key
        } else {
          nestedObj[k] = { ...nestedObj[k] } // Create a shallow copy of the nested object
          nestedObj = nestedObj[k] // Move to the next level of nested object
        }
      }

      setFormData(updatedFormData) // Update the state with the new formData
    }
  }

  const ApplicantData = [
    { label: 'Full legal name:', value: formData?.applicant.fullLegalName, type: 'text', update: 'applicant.fullLegalName' },
    { label: 'Address:', value: formData?.applicant.address, type: 'text', update: 'applicant.address' },
    { label: 'Phone & fax:', value: formData?.applicant?.phoneAndFax, type: 'text', update: 'applicant.phoneAndFax' },
    { label: 'Email:', value: formData?.applicant?.email, type: 'email', update: 'applicant.email' },
  ];
  const ApplicantLawyerData = [
    { label: 'Full legal name:', value: formData?.applicantsLawyer.fullLegalName, type: 'text', update: 'applicantsLawyer.fullLegalName' },
    { label: 'Address:', value: formData?.applicantsLawyer.address, type: 'text', update: 'applicantsLawyer.address' },
    { label: 'Phone & fax:', value: formData?.applicantsLawyer.phoneAndFax, type: 'text', update: 'applicantsLawyer.phoneAndFax' },
    { label: 'Email:', value: formData?.applicantsLawyer.email, type: 'email', update: 'applicantsLawyer.email' },
  ];
  const RespondentData = [
    { label: 'Full legal name:', value: formData?.respondent.fullLegalName, type: 'text', update: 'respondent.fullLegalName' },
    { label: 'Address:', value: formData?.respondent.address, type: 'text', update: 'respondent.address' },
    { label: 'Phone & fax:', value: formData?.respondent.phoneAndFax, type: 'text', update: 'respondent.phoneAndFax' },
    { label: 'Email:', value: formData?.respondent.email, type: 'email', update: 'respondent.email' },
  ];

  const RespondentLawyerData = [
    { label: 'Full legal name:', value: formData?.respondentsLawyer.fullLegalName, type: 'text', update: 'respondentsLawyer.fullLegalName' },
    { label: 'Address:', value: formData?.respondentsLawyer.address, type: 'text', update: 'respondentsLawyer.address' },
    { label: 'Phone & fax:', value: formData?.respondentsLawyer.phoneAndFax, type: 'text', update: 'respondentsLawyer.phoneAndFax' },
    { label: 'Email:', value: formData?.respondentsLawyer.email, type: 'email', update: 'respondentsLawyer.email' },
  ];

  const AssigneeData = [
    { label: 'Full legal name:', value: formData?.assignee?.fullLegalName, type: 'text', update: 'assignee.fullLegalName' },
    { label: 'Address:', value: formData?.assignee?.address, type: 'text', update: 'assignee.address' },
    { label: 'Phone & fax:', value: formData?.assignee?.phoneAndFax, type: 'text', update: 'assignee.phoneAndFax' },
    { label: 'Email:', value: formData?.assignee?.email, type: 'email', update: 'assignee.email' },
  ];

  const AssigneeLawyerData = [
    { label: 'Full legal name:', value: formData?.assigneeLawyer?.fullLegalName, type: 'text', update: 'assigneeLawyer.fullLegalName' },
    { label: 'Address:', value: formData?.assigneeLawyer?.address, type: 'text', update: 'assigneeLawyer.address' },
    { label: 'Phone & fax:', value: formData?.assigneeLawyer?.phoneAndFax, type: 'text', update: 'assigneeLawyer.phoneAndFax' },
    { label: 'Email:', value: formData?.assigneeLawyer?.email, type: 'email', update: 'assigneeLawyer.email' },
  ];

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className='pdf-form pdf-form-8A mt-20px px-4' ref={targetRef}>
          <FormHeading heading={"ONTARIO"} />
          <Row className='pb-3'>

            <Col xs={9}>
              <BorderLessInput
                label={"(Name of Court)"}
                type={"text"}
                fileno
                onChange={fillFormData}
                update={'court_info.courtName'}
                value={formData?.court_info?.courtName}
                style={{ padding: "6px 0" }}

              />
              <BorderLessInput
                label={"Court Office Address"}
                type={"text"}
                fileno
                onChange={fillFormData}
                update={'court_info.courtOfficeAddress'}
                value={formData?.court_info?.courtOfficeAddress}
                style={{ padding: "6px 0" }}

              />
            </Col>
            <Col xs={3}>
              <BorderLessInput
                value={formData?.court_info?.courtFileNumber}
                label={"Court File Number"}
                type={"text"}
                onChange={fillFormData}
                update={"court_info.courtFileNumber"}
                topheading
                style={{ marginTop: "-25px", border: "1px solid black" }}
                name="court-number"
              />

              <FormInfo
                number="15B"
                format={"Response to Motion to Change"}
              />
            </Col>
          </Row>

          <Row className='py-1'>
            <Col xs={6} className='ps-0'>
              <ApplicationTable heading="Applicant(s)" data={ApplicantData} onChange={fillFormData} />
            </Col>
            <Col xs={6} className='pe-0'>
              <ApplicationTable heading="Applicant(s) Lawyer" data={ApplicantLawyerData} onChange={fillFormData} />
            </Col>
            <Col xs={6} className='ps-0'>
              <ApplicationTable heading="Respondent(s)" data={RespondentData} onChange={fillFormData} />
            </Col>
            <Col xs={6} className='pe-0'>
              <ApplicationTable heading="Respondent(s) Lawyer" data={RespondentLawyerData} onChange={fillFormData} />
            </Col>
            <Col xs={6} className='ps-0'>
              <ApplicationTable heading="Assignee of Support Order (if applicable)" data={AssigneeData} onChange={fillFormData} />
            </Col>
            <Col xs={6} className='pe-0'>
              <ApplicationTable heading="Assignee's Lawyer" data={AssigneeLawyerData} onChange={fillFormData} />
            </Col>
          </Row>

          <div className='py-4'>
            <p className='sub-heading text-decoration-underline pb-1'>
              PART A-BASIC INFORMATION
            </p>
          </div>
          <div>

          </div>

          <div className='data-input '>
            <span className='fw-bold text-nowrap '>
              My name is
            </span>
            <span className='label '><i>(full legal name) </i></span>
            <input
              type='text'
              className='custom-input-control'
              value={formData?.applicant?.fullLegalName}
              onChange={fillFormData('applicant.fullLegalName')}
            />
          </div>

          <div>

            <ol type="number">
              <li id='item_1'>
                <FormLabel
                  label={`I am the responding party to this motion to change.`}
                  isBold
                />

              </li>
              <li id='item_2'>
                <div className='data-input' style={{ justifyContent: "start" }}>
                  <FormLabel
                    label={
                      <>
                        <span className='fw-bold'>I live in</span> <i >(municipality & province)</i>
                      </>
                    }
                  />
                  <FormCustomInput
                    value={formData?.applicant?.municipality}
                    fillFormData={fillFormData}
                    update={`applicant.municipality`}
                    type={`text`}
                  />
                  <FormLabel
                    label={`and I swear/affirm that the following is true:`}
                    isBold
                  />
                </div>
              </li>
              <li id="item_3">
                <div className='form-check'>
                  <CheckBox
                    labelinput={"item3.isChecked"}
                    type={"checkbox"}
                    value={formData?.item3?.isChecked || false}
                    checked={formData?.item3?.isChecked || false}
                    fillFormData={changeFormCheck}
                    checkbox={true}
                    inline
                  />
                  <FormLabel
                    label={`I request to convert this motion to change support from s. 17 to s. 18.1 of the Divorce Act. (You can make this request only if you live outside of Ontario and this motion includes support claims under the Divorce Act. If the court grants your request, motion documents will be sent to Ontario’s designated authority under the Interjurisdictional Support Orders Act, 2002.)`}
                  />
                </div>
              </li>
              <li id="item_4">
                <div className='form-check'>
                  <CheckBox
                    labelinput={"item4.isChecked"}
                    type={"checkbox"}
                    value={formData?.item4?.isChecked || false}
                    checked={formData?.item4?.isChecked || false}
                    fillFormData={changeFormCheck}
                    checkbox={true}
                    inline
                  />
                  <FormLabel
                    label={`I agree with the following claims made by the requesting party at paragraph 11 of their Motion to Change Form (Form 15) (list the claims you agree with, for example paragraph 11(a), (b), (c), etc.):`}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <span>
                    Paragraph 11
                  </span>
                  <BorderLessInput
                    type={"text"}
                    fileno
                    onChange={fillFormData}
                    update={'item4.paragraph11.line1'}
                    value={formData?.item4?.paragraph11?.line1}
                    style={{ width: "30px", margin: "0px 10px", padding: "0" }}

                  />
                  <div className='mt-2'>,</div>
                  <BorderLessInput
                    type={"text"}
                    fileno
                    onChange={fillFormData}
                    update={'item4.paragraph11.line2'}
                    value={formData?.item4?.paragraph11?.line2}
                    style={{ width: "30px", margin: "0px 10px" }}

                  />
                  <div className='mt-2'>,</div>
                  <BorderLessInput
                    type={"text"}
                    fileno
                    onChange={fillFormData}
                    update={'item4.paragraph11.line3'}
                    value={formData?.item4?.paragraph11?.line3}
                    style={{ width: "30px", margin: "0px 10px" }}

                  />
                  <div className='mt-2'>,</div>
                  <BorderLessInput
                    type={"text"}
                    fileno
                    onChange={fillFormData}
                    update={'item4.paragraph11.line4'}
                    value={formData?.item4?.paragraph11?.line4}
                    style={{ width: "30px", margin: "0px 10px" }}

                  />
                  <div className='mt-2'>,</div>
                  <BorderLessInput
                    type={"text"}
                    fileno
                    onChange={fillFormData}
                    update={'item4.paragraph11.line5'}
                    value={formData?.item4?.paragraph11?.line5}
                    style={{ width: "30px", margin: "0px 10px" }}

                  />
                  <div className='mt-2'>,</div>
                  <BorderLessInput
                    type={"text"}
                    fileno
                    onChange={fillFormData}
                    update={'item4.paragraph11.line6'}
                    value={formData?.item4?.paragraph11?.line6}
                    style={{ width: "30px", margin: "0px 10px" }}

                  />
                  <div className='mt-2'>,</div>
                  <BorderLessInput
                    type={"text"}
                    fileno
                    onChange={fillFormData}
                    update={'item4.paragraph11.line7'}
                    value={formData?.item4?.paragraph11?.line7}
                    style={{ width: "30px", margin: "0px 10px" }}

                  />
                  <div className='mt-2'>,</div>
                  <BorderLessInput
                    type={"text"}
                    fileno
                    onChange={fillFormData}
                    update={'item4.paragraph11.line8'}
                    value={formData?.item4?.paragraph11?.line8}
                    style={{ width: "30px", margin: "0px 10px" }}

                  />
                  <div className='mt-2'>,</div>
                  <BorderLessInput
                    type={"text"}
                    fileno
                    onChange={fillFormData}
                    update={'item4.paragraph11.line9'}
                    value={formData?.item4?.paragraph11?.line9}
                    style={{ width: "30px", margin: "0px 10px" }}

                  />
                  <div className='mt-2'>,</div>
                  <BorderLessInput
                    type={"text"}
                    fileno
                    onChange={fillFormData}
                    update={'item4.paragraph11.line10'}
                    value={formData?.item4?.paragraph11?.line10}
                    style={{ width: "30px", margin: "0px 10px" }}

                  />
                  <div className='mt-2'>,</div>

                </div>
              </li>
              <li id="item_5">
                <div className='form-check'>
                  <CheckBox
                    labelinput={"item5.isChecked"}
                    type={"checkbox"}
                    value={formData?.item5?.isChecked || false}
                    checked={formData?.item5?.isChecked || false}
                    fillFormData={changeFormCheck}
                    checkbox={true}
                    inline
                  />
                  <FormLabel
                    label={`I disagree with the following claims made by the requesting party at paragraph 11 of their Motion to Change Form (Form 15) (list the claims you agree with, for example paragraph 11(a), (b), (c), etc.):`}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <span >
                    Paragraph 11
                  </span>
                  <BorderLessInput
                    type={"text"}
                    fileno
                    onChange={fillFormData}
                    update={'item5.paragraph11.line1'}
                    value={formData?.item5?.paragraph11?.line1}
                    style={{ width: "30px", margin: "0px 10px", padding: "0" }}

                  />
                  <div className='mt-2'>,</div>
                  <BorderLessInput
                    type={"text"}
                    fileno
                    onChange={fillFormData}
                    update={'item5.paragraph11.line2'}
                    value={formData?.item5?.paragraph11?.line2}
                    style={{ width: "30px", margin: "0px 10px" }}

                  />
                  <div className='mt-2'>,</div>
                  <BorderLessInput
                    type={"text"}
                    fileno
                    onChange={fillFormData}
                    update={'item5.paragraph11.line3'}
                    value={formData?.item5?.paragraph11?.line3}
                    style={{ width: "30px", margin: "0px 10px" }}

                  />
                  <div className='mt-2'>,</div>
                  <BorderLessInput
                    type={"text"}
                    fileno
                    onChange={fillFormData}
                    update={'item5.paragraph11.line4'}
                    value={formData?.item5?.paragraph11?.line4}
                    style={{ width: "30px", margin: "0px 10px" }}

                  />
                  <div className='mt-2'>,</div>
                  <BorderLessInput
                    type={"text"}
                    fileno
                    onChange={fillFormData}
                    update={'item5.paragraph11.line5'}
                    value={formData?.item5?.paragraph11?.line5}
                    style={{ width: "30px", margin: "0px 10px" }}

                  />
                  <div className='mt-2'>,</div>
                  <BorderLessInput
                    type={"text"}
                    fileno
                    onChange={fillFormData}
                    update={'item5.paragraph11.line6'}
                    value={formData?.item5?.paragraph11?.line6}
                    style={{ width: "30px", margin: "0px 10px" }}

                  />
                  <div className='mt-2'>,</div>
                  <BorderLessInput
                    type={"text"}
                    fileno
                    onChange={fillFormData}
                    update={'item5.paragraph11.line7'}
                    value={formData?.item5?.paragraph11?.line7}
                    style={{ width: "30px", margin: "0px 10px" }}

                  />
                  <div className='mt-2'>,</div>
                  <BorderLessInput
                    type={"text"}
                    fileno
                    onChange={fillFormData}
                    update={'item5.paragraph11.line8'}
                    value={formData?.item5?.paragraph11?.line8}
                    style={{ width: "30px", margin: "0px 10px" }}

                  />
                  <div className='mt-2'>,</div>
                  <BorderLessInput
                    type={"text"}
                    fileno
                    onChange={fillFormData}
                    update={'item5.paragraph11.line9'}
                    value={formData?.item5?.paragraph11?.line9}
                    style={{ width: "30px", margin: "0px 10px" }}

                  />
                  <div className='mt-2'>,</div>
                  <BorderLessInput
                    type={"text"}
                    fileno
                    onChange={fillFormData}
                    update={'item5.paragraph11.line10'}
                    value={formData?.item5?.paragraph11?.line10}
                    style={{ width: "30px", margin: "0px 10px" }}

                  />
                  <div className='mt-2'>,</div>

                </div>
              </li>
              <li id="item_6">
                <FormLabel
                  label={
                    <>
                      I disagree with the claims made by the requesting party because <i>(briefly explain why you do not think that the current order/agreement should be changed):</i>
                    </>
                  }
                />
                <DynamicTextArea
                  rows={5}
                  updates={'item6.details'}
                  fillFormData={fillFormData}
                  value={formData?.item6?.details}
                />
              </li>
              <li id="item_7">
                <div className='form-check'>
                  <CheckBox
                    labelinput={"item7.isChecked"}
                    type={"checkbox"}
                    value={formData?.item7?.isChecked || false}
                    checked={formData?.item7?.isChecked || false}
                    fillFormData={changeFormCheck}
                    checkbox={true}
                    inline
                  />
                  <FormLabel
                    label={
                      <>
                        I also disagree with the following facts in the requesting party’s Motion to Change Form (Form 15) <i>(briefly explain what information you do not agree with and explain why):</i>
                      </>
                    }
                  />
                </div>
                <DynamicTextArea
                  rows={5}
                  updates={'item7.details'}
                  fillFormData={fillFormData}
                  value={formData?.item7?.details}
                />
              </li>
              <li id="item_8">
                <div className='form-check'>
                  <CheckBox
                    labelinput={"item8.isChecked"}
                    type={"checkbox"}
                    value={formData?.item8?.isChecked || false}
                    checked={formData?.item8?.isChecked || false}
                    fillFormData={changeFormCheck}
                    checkbox={true}
                    inline
                  />
                  <FormLabel
                    label={`I am asking that the motion to change (except the parts that I agree with) be dismissed with costs.`}
                  />
                </div>
              </li>
              <li id="item_9">
                <div className='form-check'>
                  <CheckBox
                    labelinput={"item9.isChecked"}
                    type={"checkbox"}
                    value={formData?.item9?.isChecked || false}
                    checked={formData?.item9?.isChecked || false}
                    fillFormData={changeFormCheck}
                    checkbox={true}
                    inline
                  />
                  <FormLabel
                    label={`If this motion to change includes a request to change support, please complete the following:`}
                  />
                </div>
                <ol type='a'>
                  <li>
                    <div className='d-flex flex-row'>
                      <FormLabel
                        label={`I am the`}
                      />
                      <CheckBox
                        labelinput={"item9.a"}
                        label={'support payor'}
                        type={"checkbox"}
                        value={'payor'}
                        checked={formData?.item9?.a === 'payor'}
                        fillFormData={fillFormData}
                        checkbox={true}
                      />
                      <CheckBox
                        labelinput={"item9.a"}
                        label={'support recipient'}
                        type={"checkbox"}
                        value={'recipient'}
                        checked={formData?.item9?.a === 'recipient'}
                        fillFormData={fillFormData}
                        checkbox={true}
                      />

                    </div>
                  </li>
                  <li>
                    <FormLabel
                      label={`I have attached a completed confirmation of assignment form, returned to me by the Ontario Ministry ofChildren, Community and Social Services showing that:`}
                    />
                    <CheckBox
                      labelinput={"item9.b"}
                      label={'The order has not been assigned to a government agency.'}
                      type={"checkbox"}
                      value={'notassigned'}
                      checked={formData?.item9?.b === 'notassigned'}
                      fillFormData={fillFormData}
                      checkbox={true}
                      container
                    />
                    <CheckBox
                      labelinput={"item9.b"}
                      label={
                        <>
                          The order has been assigned to a government agency. <i>(You must serve a copy of this document, with all
                            attachments, on the government agency.)</i>
                        </>
                      }
                      type={"checkbox"}
                      value={'assigned'}
                      checked={formData?.item9?.b === 'assigned'}
                      fillFormData={fillFormData}
                      checkbox={true}
                      container
                    />
                  </li>
                  <li>
                    <span class="label">
                      Since the order/agreement for child support was made, a Notice of Recalculation was issued by the online Child Support Service dated <input type="date" style={{ width: "180px", border: "none", borderBottom: "1px solid black" }} />  <i>(please attach).</i>
                    </span>
                  </li>
                  <li>
                    <FormLabel
                      label={
                        <>
                          Please give information about your income and the other party’s income (if known) for the past 3 years (a party’s income should be their total income from all sources as listed on line 150 of their Income Tax Return):
                        </>
                      }
                    />
                    <IncomeTable
                      formData={formData}
                      fillFormData={fillFormData}
                    />
                  </li>

                </ol>
              </li>

              <div className='py-2 text-center'>
                <small className='sub-heading text-decoration-underline pb-1'>
                  PART B – CHANGES THAT THE RESPONDING PARTY WANTS TO MAKE
                </small>
              </div>
              <div className="pb-4 text-center">
                <span className="fw-bold pb-1 ">
                  Note: Do not complete this Part if you are only asking to dismiss the requesting party’s motion to change.
                </span>
              </div>

              <li id="item_10">
                <FormLabel
                  label={`I want to change the following (check all that apply):`}
                />
                <Row className='ps-5'>
                  <CheckBox
                    fillFormData={changeFormCheck}
                    labelinput={'item10.decision_making'}
                    label='Decision-making responsibility'
                    type={"checkbox"}
                    value={formData?.item10?.decision_making?.checked}
                    checked={formData?.item10?.decision_making?.checked}
                    checkbox={true}
                    withCol
                  />
                  <CheckBox
                    fillFormData={changeFormCheck}
                    labelinput={'item10.child_support_table'}
                    label='Child support - table amount'
                    type={"checkbox"}
                    value={formData?.item10?.child_support_table?.checked}
                    checked={formData?.item10?.child_support_table?.checked}
                    checkbox={true}
                    withCol
                  />
                  <CheckBox
                    fillFormData={changeFormCheck}
                    labelinput={'item10.child_support_expenses'}
                    label='Child support - special or extraordinary expenses (list type of expenses):'
                    type={"checkbox"}
                    value={formData?.item10?.child_support_expenses?.checked}
                    checked={formData?.item10?.child_support_expenses?.checked}
                    checkbox={true}
                    withCol
                  />
                  <CheckBox
                    fillFormData={changeFormCheck}
                    labelinput={'item10.parenting_time'}
                    label='Parenting time'
                    type={"checkbox"}
                    value={formData?.item10?.parenting_time?.checked}
                    checked={formData?.item10?.parenting_time?.checked}
                    checkbox={true}
                    withCol
                  />
                  <CheckBox
                    fillFormData={changeFormCheck}
                    labelinput={'item10.spousal_support'}
                    label='Spousal support'
                    type={"checkbox"}
                    value={formData?.item10?.spousal_support?.checked}
                    checked={formData?.item10?.spousal_support?.checked}
                    checkbox={true}
                    withCol
                  />
                  <CheckBox
                    fillFormData={changeFormCheck}
                    labelinput={'item10.contact'}
                    label='Contact'
                    type={"checkbox"}
                    value={formData?.item10?.contact?.checked}
                    checked={formData?.item10?.contact?.checked}
                    checkbox={true}
                    withCol
                  />
                  <CheckBox
                    fillFormData={changeFormCheck}
                    labelinput={'item10.other_details'}
                    label='Other (give details):'
                    type={"checkbox"}
                    value={formData?.item10?.other_details?.checked}
                    checked={formData?.item10?.other_details?.checked}
                    checkbox={true}
                    withCol
                  />
                </Row>

                <DynamicTextArea
                  rows={5}
                  updates={'item10.other_details.details'}
                  fillFormData={fillFormData}
                  value={formData?.item10?.other_details?.details}
                />
              </li>
              <li id="item_11">
                <FormLabel
                  label={
                    <>
                      want to change the following specific terms of the current order/agreement <i>(please provide the paragraph number of
                        each term and the wording of the term exactly as it appears in the order/agreement):</i>
                    </>
                  }
                />
                <ol type="a" >
                  {nestedItems.map((items, index) => (
                    <li key={index}>
                      <ol type={types[index % types.length]} className='p-0'>
                        {items.map((item, subIndex) => (
                          <>
                            {/* {subIndex}.{types[index % types.length]}.{item} */}
                            <ListItem
                              key={subIndex}
                              text={item}
                              showInput={true} // Show input for all items in nested list
                              onChange={fillFormData(``)}
                            />
                          </>
                        ))}
                      </ol>
                    </li>
                  ))}
                </ol>
              </li>

              <div className='pt-5 text-center'>
                <p className='sub-heading text-decoration-underline pb-1'>
                  PART C – WHY THE RESPONDING PARTY WANTS THE CHANGES
                </p>
              </div>

              <BoldandThinText bold={"Note: The responding party must either complete the remainder of this form or complete a separate affidavit (Form 14A) to give the important facts that explain why the court should change the current order/agreement."} />


              <li id="item_12">
                <FormLabel
                  label={` Are you and the other party following the current order/agreement?`}
                />
                <CheckBoxGroup>
                  <CheckBox
                    labelinput={"item12.checked"}
                    label={'Yes'}
                    type={"checkbox"}
                    value={'yes'}
                    checked={formData?.item12?.checked === 'yes'}
                    fillFormData={fillFormData}
                    checkbox={true}
                  />
                  <CheckBox
                    labelinput={"item12.checked"}
                    label={<>No. <i>(Give details in the box below.)</i></>}
                    type={"checkbox"}
                    value={'no'}
                    checked={formData?.item12?.checked === 'no'}
                    fillFormData={fillFormData}
                    checkbox={true}
                  />
                </CheckBoxGroup>

                <DynamicTextArea
                  rows={5}
                  updates={'item12.details'}
                  fillFormData={fillFormData}
                  value={formData?.item12?.details}
                />

              </li>
              <li id="item_13">
                <FormLabel
                  label={`Briefly give the facts that show why the court should change the order/agreement, including how your situation has changed since the order/agreement was made:`}
                />
                <DynamicTextArea
                  rows={5}
                  updates={'item13.details'}
                  fillFormData={fillFormData}
                  value={formData?.item13?.details}
                />
              </li>
              <div className='py-4'>
                <p className='sub-heading text-decoration-underline pb-1'>
                  PART D - ADDITIONAL INFORMATION FOR SUPPORT CASE ONLY
                </p>
              </div>
              <div className="pb-4 text-center">
                <span className="fw-bold pb-1 ">
                  Note: The requesting party must complete this Part <span className='text-decoration-underline'>only</span> if asking to change child support or spousal support.
                  {' '}
                </span>
              </div>
              <li id="item_14">

                <CheckBoxGroup row>
                  <FormLabel
                    label={`Is support owed under the current order/agreement?`}
                  />
                  <CheckBox
                    labelinput={"item14.checked"}
                    label={'Yes'}
                    type={"checkbox"}
                    value={'yes'}
                    checked={formData?.item14?.checked === 'yes'}
                    fillFormData={fillFormData}
                    checkbox={true}
                  />
                  <CheckBox
                    labelinput={"item14.checked"}
                    label={'No'}
                    type={"checkbox"}
                    value={'no'}
                    checked={formData?.item14?.checked === 'no'}
                    fillFormData={fillFormData}
                    checkbox={true}
                  />
                </CheckBoxGroup>


              </li>
              <li id="item_15">
                <FormLabel
                  label={`If yes, please give details about the support that is owed:`}
                />
                <SupportTable onChange={fillFormData} formData={formData} />
              </li>
              <li id="item_16">
                <FormLabel
                  label={` When do you want the change in support to start? (check one)`}
                />
                <Row>
                  <Col md={12}>
                    <CheckBox
                      id='today'
                      label='Today'
                      type='checkbox'
                      value={'today'}
                      labelinput={"item16.checked"}
                      fillFormData={fillFormData}
                      checked={formData?.item16?.checked === 'today'}
                    />
                  </Col>
                  <Col md={5}>
                    <CheckBox
                      id='today'
                      label='Before today (give exact date: d,m,y)'
                      type='checkbox'
                      value={'before'}
                      labelinput={"item16.checked"}
                      fillFormData={fillFormData}
                      checked={formData?.item16?.checked === 'before'}
                    />
                  </Col>
                  <Col md={7}>
                    <div className='w-100'>
                      <div className='data-input mt-2'>
                        <input
                          type='date'
                          className='custom-input-control'
                          value={formData?.item16?.date}
                          onChange={fillFormData(`item16.date`)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

              </li>
              <li id="item_17">
                <FormLabel
                  label={`If you are asking to change support starting on a date before today, please answer the following:`}
                />

                <ol type='a'>
                  <li>
                    <FormLabel
                      label={`What date did you first ask the other party for updated income information or to change support?`}
                    />
                    <DynamicTextArea
                      rows={5}
                      updates={'item17.a.details'}
                      fillFormData={fillFormData}
                      value={formData?.item17?.a?.details}
                    />
                  </li>
                  <li>
                    <FormLabel
                      label={`Did the other party do anything to make it difficult for you to know if support should change?`}
                    />
                    <CheckBoxGroup>
                      <CheckBox
                        labelinput={"item17.b.checked"}
                        label={<>No.</>}
                        type={"checkbox"}
                        value={'no'}
                        checked={formData?.item17?.b?.checked === 'no'}
                        fillFormData={fillFormData}
                        checkbox={true}
                      />
                      <CheckBox
                        labelinput={"item17.b.checked"}
                        label={<>Yes <i>(Give details in the box below.)</i></>}
                        type={"checkbox"}
                        value={'yes'}
                        checked={formData?.item17?.b?.checked === 'yes'}
                        fillFormData={fillFormData}
                        checkbox={true}
                      />
                    </CheckBoxGroup>
                    <DynamicTextArea
                      rows={5}
                      updates={'item17.b.details'}
                      fillFormData={fillFormData}
                      value={formData?.item17?.b?.details}
                    />
                  </li>
                  <li>
                    <FormLabel
                      label={`Why didn’t you ask the court to change support sooner?`}
                    />
                    <DynamicTextArea
                      rows={5}
                      updates={'item17.c.details'}
                      fillFormData={fillFormData}
                      value={formData?.item17?.c?.details}
                    />
                  </li>
                  <li>
                    <FormLabel
                      label={`What are your circumstances and the child’s circumstances that support this request?`}
                    />
                    <DynamicTextArea
                      rows={5}
                      updates={'item17.d.details'}
                      fillFormData={fillFormData}
                      value={formData?.item17?.d?.details}
                    />
                  </li>

                </ol>

              </li>
            </ol>

            <Certificates responding />

            <Signatures variant={'one'} formData={formData} fillFormData={fillFormData} />

            <Certificates lawyers fillFormData={fillFormData} formData={formData} />

            <div className='pt-3'>
              <p className='sub-heading text-decoration-underline pb-1'>
                IMPORTANT INFORMATION
              </p>
            </div>

            <div className='mt-4'>
              <div>
                <span className='fw-bold text-nowrap small'>
                  YOU SHOULD GET LEGAL ADVICE RIGHT AWAY.
                </span>
                <span className='label small'> For help finding legal advice, you can contact. {' '}</span>
              </div>
            </div>
            <ul className='label'>
              <li>The Law Society of Ontario's Referral Service at  <span className='text-decoration-underline' style={{ color: 'blue' }}> www.lsrs.info.</span> If you are unable to use this online service, you can call 416-947-5255 or toll-free at 1-855-947-5255.</li>
              <li>The Law Society of Ontario's list of lawyers at <span className='text-decoration-underline' style={{ color: 'blue' }}> www.lawyerandparalegal.directory.</span></li>
              <li>Legal Aid Ontario at  <span className='text-decoration-underline' style={{ color: 'blue' }}> www.legalaid.on.ca</span> or 1-800-668-8258 (subject to your eligibility).</li>
            </ul>
            <div>
              <span className='fw-bold text-nowrap small'>
                IF THIS CASE HAS NOT BEEN SCHEDULED FOR A TRIAL AFTER 365 DAYS,
              </span>
              <span className='label small'> the court clerk will send a warning that the case will be dismissed in 60 days unless a party asks to schedule a conference or files proof that the case has been settled.{' '}</span>
            </div>

            <div className='pt-1'>
              <span className='fw-bold text-nowrap small'>
                IF YOU AGREE WITH THE CHANGES{' '}
              </span>
              <span className='label small'> that the other party wants to make, you must complete the applicable parts of the Consent Motion to Change (Form 15C), give the original version to the other party, and give a copy to any assignee?. The other party may then finish completing the form and file it with the court.{' '}</span>
            </div>
            <div className='pt-2'>
              <span className='fw-bold text-nowrap small'>
                IF YOU DON'T AGREE WITH THE CHANGES{' '}
              </span>
              <span className='label small'>or you want to ask for different changes, you must:{' '}</span>
            </div>
            <Row>
              <Col md={12}>
                <CheckBox
                  labelinput={"important_info.complete.checked"}
                  label={
                    <>
                      <span className='fw-bold'>Complete{' '}</span>
                      the following forms:{' '}
                    </>
                  }
                  type={"checkbox"}
                  value={formData?.important_info?.complete?.checked || false}
                  checked={formData?.important_info?.complete?.checked}
                  fillFormData={changeFormCheck}
                  checkbox={true}
                />
              </Col>
            </Row>
            <Row className='ps-5'>
              <Col md={12}>
                <CheckBox
                  id='order'
                  label={
                    <>
                      <span className='fw-bold'>Response to Motion to Change (Form 15B).</span>
                    </>
                  }
                  type={"checkbox"}
                  value={formData?.important_info?.response?.checked || false}
                  checked={formData?.important_info?.response?.checked}
                  fillFormData={changeFormCheck}
                  checkbox={true}
                />
              </Col>
              <Col md={12}>
                <CheckBox
                  id='agreement'
                  name='agreement'
                  label={
                    <>
                      An{' '}
                      <span className='fw-bold'> Affidavit (decision-making responsibility, parenting time, contact) (Form 35.1){' '}</span>
                      if you or the other party is asking to change your parenting or contact arrangement.
                    </>
                  }
                  type={"checkbox"}
                  value={formData?.important_info?.affidavit?.checked || false}
                  checked={formData?.important_info?.affidavit?.checked}
                  fillFormData={changeFormCheck}
                  checkbox={true}
                />
              </Col>
              <Col md={12}>
                <CheckBox
                  id='agreement'
                  name='agreement'
                  label={
                    <>
                      A{' '}
                      <span className='fw-bold'>Financial Statement (Form 13){' '}</span>
                      if you or the other party is asking to change any child support or spousal support that you pay.
                    </>
                  }
                  type={"checkbox"}
                  value={formData?.important_info?.financial_statement?.checked || false}
                  checked={formData?.important_info?.financial_statement?.checked}
                  fillFormData={changeFormCheck}
                  checkbox={true}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <CheckBox
                  id='order'
                  name='order'
                  label={
                    <>
                      <span className='fw-bold'>Serve{' '}</span>
                      a copy of all your completed forms on the other party.{' '}
                    </>
                  }
                  type={"checkbox"}
                  value={formData?.important_info?.serve?.checked || false}
                  checked={formData?.important_info?.serve?.checked}
                  fillFormData={changeFormCheck}
                  checkbox={true}
                />
              </Col>
              <Col md={12}>
                <CheckBox
                  id='order'
                  name='order'
                  label={
                    <>
                      Complete an{' '}
                      <span className='fw-bold'>Affidavit of Service (Form 6B).</span>
                    </>
                  }
                  type={"checkbox"}
                  value={formData?.important_info?.affidavit_service?.checked || false}
                  checked={formData?.important_info?.affidavit_service?.checked}
                  fillFormData={changeFormCheck}
                  checkbox={true}
                />
              </Col>
              <Col md={12}>
                <CheckBox
                  id='order'
                  name='order'
                  label={
                    <>
                      <span className='fw-bold'>File{' '}</span>
                      all your completed forms and the Affidavit of Service with the court.
                    </>
                  }
                  type={"checkbox"}
                  value={formData?.important_info?.file?.checked || false}
                  checked={formData?.important_info?.file?.checked}
                  fillFormData={changeFormCheck}
                  checkbox={true}
                />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  )
}

export default ONTFORM15B