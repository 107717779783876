import React from 'react';
import Layout from '../../components/LayoutComponents/Layout';
import { useSelector } from "react-redux";
import ArchiveComplianceTable from './ArchiveComplianceTable';
import FooterDash from '../../components/Dashboard/FooterDash/FooterDash';
import ArchiveChecklist from './ArchiveChecklist';
import ArchiveReportTable from './ArchiveReportTable';

const Archive = () => {

  const { response } = useSelector((state) => state.userProfileInfo);



  const [ArchiveFilter, setArchiveFilter] = React.useState('Reports');
  console.log('arcAAAAAAAAAAF',ArchiveFilter);


  return (
    <Layout title={`Welcome ${response?.username ? response?.username : ""}`}>

      {
        ArchiveFilter == 'Monthly Checklist' ? (
          <ArchiveChecklist setArchiveFilter={setArchiveFilter} ArchiveFilter={ArchiveFilter} />
        ) : ArchiveFilter == 'Compliance Form' ? (
          <ArchiveComplianceTable setArchiveFilter={setArchiveFilter} ArchiveFilter={ArchiveFilter} />
        ) : (
          <ArchiveReportTable setArchiveFilter={setArchiveFilter} ArchiveFilter={ArchiveFilter} />
        )
      }

      <FooterDash />
    </Layout>
  )
}

export default Archive
