import React from 'react'
import RadioChecks from '../../forms/shared/RadioChecks'
import BorderLessInput from '../../forms/shared/BorderLessInput'
import { Col, Row } from 'react-bootstrap'
import { calculateAge } from '../../../../utils/matterValidations/matterValidation'
import CheckBox from '../../forms/shared/CheckBox'

const FamilyHistory = ({ formData, fillFormData, changeFormCheck }) => {
    return (
        <>


            <div className='row pb-20px '>
                <p className='sub-heading'>FAMILY HISTORY</p>
                <div>
                    <div className='data-input'>
                        <span className='fw-bold'>APPLICANT: </span>
                        <span className='label'>Age: </span>
                        <input
                            type='text'
                            className='custom-input-control'
                            style={{ width: '300px' }}
                            value={calculateAge(formData?.applicant?.dateOfBirth)}
                            onChange={fillFormData('familyHistory.applicant.age')}
                        />
                        <span className='label'> Birthdate: </span>
                        <input
                            type='date'
                            className='custom-input-control'
                            value={formData?.applicant?.dateOfBirth}
                            onChange={fillFormData('familyHistory.applicant.dateOfBirth')}
                        />
                    </div>

                    <div className='data-input'>
                        <span className='label'>
                            Resident in (municipality & province){' '}
                        </span>
                        <input
                            type='text'
                            className='custom-input-control'

                            value={formData?.applicant?.municipality}
                            onChange={fillFormData(
                                'applicant.municipality'
                            )}
                        />
                        <span className='label'> Since (date) </span>
                        <input
                            type='date'
                            className='custom-input-control'
                            value={formData?.applicant?.since}
                            onChange={fillFormData('familyHistory.applicant.since')}
                        />
                    </div>

                    <div className='data-input'>
                        <span className='label'>
                            First name on the day before the marriage date:{' '}
                        </span>
                        <input
                            type='text'
                            className='form-control'
                            value={formData?.applicant?.firstNameBeforeMarriage}
                            onChange={fillFormData(
                                'applicant.firstNameBeforeMarriage'
                            )}
                        />
                    </div>

                    <div className='data-input'>
                        <span className='label'>
                            Last name on the day before the marriage date:{' '}
                        </span>
                        <input
                            type='text'
                            className='form-control'
                            value={formData?.applicant?.lastNameBeforeMarriage}
                            onChange={fillFormData(
                                'applicant.lastNameBeforeMarriage'
                            )}
                        />
                    </div>

                    <div className='label'>Gender on the day before the marriage: </div>

                    <div className="d-flex py-2">
                        <div>
                            <CheckBox
                                label={"Male"}
                                type={"checkbox"}
                                value={'male'}
                                checked={formData?.applicant?.genderBeforeMarriage === "male"}
                                fillFormData={fillFormData}
                                labelinput={"applicant.genderBeforeMarriage"}
                                checkbox={true}
                            />
                        </div>
                        <div>
                            <CheckBox
                                label={"Female"}
                                type={"checkbox"}
                                value={'female'}
                                checked={formData?.applicant.genderBeforeMarriage === "female"}
                                fillFormData={fillFormData}
                                labelinput={"applicant.genderBeforeMarriage"}
                                checkbox={true}
                            />
                        </div>
                        <div>
                            <CheckBox
                                label={"Another gender"}
                                type={"checkbox"}
                                value={'another_gender'}
                                checked={formData?.applicant.genderBeforeMarriage === "another_gender"}
                                fillFormData={fillFormData}
                                labelinput={"applicant.genderBeforeMarriage"}
                                checkbox={true}
                            />
                        </div>
                        <div>
                            <CheckBox
                                label={"Gender information not available"}
                                type={"checkbox"}
                                value={'no_gender'}
                                checked={formData?.applicant.genderBeforeMarriage === "no_gender"}
                                fillFormData={fillFormData}
                                labelinput={"applicant.genderBeforeMarriage"}
                                checkbox={true}
                            />
                        </div>
                    </div>

                    <div className='row pb-10px'>
                        <div className='d-flex flex-row gap-4'>
                            <div className='label'>Divorced before? </div>


                            <div className="d-flex ">
                                <div>
                                    <CheckBox
                                        label={"No"}
                                        type={"checkbox"}
                                        value={'no'}
                                        checked={formData?.applicant?.isDivorcedBefore === "no"}
                                        fillFormData={fillFormData}
                                        labelinput={"applicant.isDivorcedBefore"}
                                        checkbox={true}
                                    />
                                </div>
                                <div>
                                    <CheckBox
                                        label={"Yes (Place and date of previous divorce)"}
                                        type={"checkbox"}
                                        value={'yes'}
                                        checked={formData?.applicant?.isDivorcedBefore === "yes"}
                                        fillFormData={fillFormData}
                                        labelinput={"applicant.isDivorcedBefore"}
                                        checkbox={true}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className='data-input'>
                            <div style={{ width: "100%" }}>
                                <BorderLessInput
                                    fileno
                                    onChange={fillFormData}
                                    update={'familyHistory.applicant.divorcedBeforePlace'}
                                    value={formData?.applicant?.divorcedBeforePlace}
                                    style={{ padding: "6px 0" }}
                                    type={"text"}
                                />
                            </div>

                            <div className='row pb-10px ml-10px mt-30px border-top border-2 border-dark' />
                        </div>


                    </div>
                </div>

                {/* Respondent */}
                <div>
                    <div className='data-input'>
                        <span className='fw-bold text-nowrap small'>
                            RESPONDENT/JOINT APPLICATION:{' '}
                        </span>
                        <span className='label'>Age: </span>
                        <input
                            type='text'
                            className='custom-input-control'
                            style={{ width: '300px' }}
                            value={calculateAge(formData?.respondent.dateOfBirth)}
                            onChange={fillFormData('familyHistory.respondent.age')}
                        />
                        <span className='label'> Birthdate: </span>
                        <input
                            type='date'
                            className='custom-input-control'
                            value={formData?.respondent?.dateOfBirth}
                            onChange={fillFormData('familyHistory.respondent.birthdate')}
                        />
                    </div>

                    <div className='data-input'>
                        <span className='label'>
                            Resident in (municipality & province){' '}
                        </span>
                        <input
                            type='text'
                            className='custom-input-control'
                            value={formData?.respondent?.municipality}
                            onChange={fillFormData(
                                'respondent.municipality'
                            )}
                        />
                        <span className='label'> Since (date) </span>
                        <input
                            type='date'
                            className='custom-input-control'
                            value={formData?.respondent?.since}
                            onChange={fillFormData('familyHistory.respondent.since')}
                        />
                    </div>

                    <div className='data-input'>
                        <span className='label'>
                            First name on the day before the marriage date:{' '}
                        </span>
                        <input
                            type='text'
                            className='custom-input-control'
                            value={formData?.respondent?.firstNameBeforeMarriage}
                            onChange={fillFormData(
                                'respondent.firstNameBeforeMarriage'
                            )}
                        />
                    </div>

                    <div className='data-input'>
                        <span className='label'>
                            Last name on the day before the marriage date:{' '}
                        </span>
                        <input
                            type='text'
                            className='custom-input-control'
                            value={formData?.respondent?.lastNameBeforeMarriage}
                            onChange={fillFormData(
                                'respondent.lastNameBeforeMarriage'
                            )}
                        />
                    </div>

                    <div className='label'>Gender on the day before the marriage: </div>

                    <div className="d-flex py-2">
                        <div>
                            <CheckBox
                                label={"Male"}
                                type={"checkbox"}
                                value={'male'}
                                checked={formData?.respondent?.genderBeforeMarriage === "male"}
                                fillFormData={fillFormData}
                                labelinput={"respondent.genderBeforeMarriage"}
                                checkbox={true}
                            />
                        </div>
                        <div>
                            <CheckBox
                                label={"Female"}
                                type={"checkbox"}
                                value={'female'}
                                checked={formData?.respondent.genderBeforeMarriage === "female"}
                                fillFormData={fillFormData}
                                labelinput={"respondent.genderBeforeMarriage"}
                                checkbox={true}
                            />
                        </div>
                        <div>
                            <CheckBox
                                label={"Another gender"}
                                type={"checkbox"}
                                value={'another_gender'}
                                checked={formData?.respondent.genderBeforeMarriage === "another_gender"}
                                fillFormData={fillFormData}
                                labelinput={"respondent.genderBeforeMarriage"}
                                checkbox={true}
                            />
                        </div>
                        <div>
                            <CheckBox
                                label={"Gender information not available"}
                                type={"checkbox"}
                                value={'no_gender'}
                                checked={formData?.respondent.genderBeforeMarriage === "no_gender"}
                                fillFormData={fillFormData}
                                labelinput={"respondent.genderBeforeMarriage"}
                                checkbox={true}
                            />
                        </div>
                    </div>

                    <div className='row pb-10px'>
                        <div className='d-flex flex-row gap-4'>
                            <div className='label'>Divorced before? </div>


                            <div className="d-flex ">
                                <div>
                                    <CheckBox
                                        label={"No"}
                                        type={"checkbox"}
                                        value={'no'}
                                        checked={formData?.respondent?.isDivorcedBefore === "no"}
                                        fillFormData={fillFormData}
                                        labelinput={"respondent.isDivorcedBefore"}
                                        checkbox={true}
                                    />
                                </div>
                                <div>
                                    <CheckBox
                                        label={"Yes (Place and date of previous divorce)"}
                                        type={"checkbox"}
                                        value={'yes'}
                                        checked={formData?.respondent?.isDivorcedBefore === "yes"}
                                        fillFormData={fillFormData}
                                        labelinput={"respondent.isDivorcedBefore"}
                                        checkbox={true}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className='data-input'>
                            <div style={{ width: "100%" }}>
                                <BorderLessInput
                                    fileno
                                    onChange={fillFormData}
                                    update={'familyHistory.respondent.divorcedBeforePlace'}
                                    value={formData?.respondent?.divorcedBeforePlace}
                                    style={{ padding: "6px 0" }}
                                    type={"text"}
                                />
                            </div>

                            <div className='row pb-10px ml-10px mt-30px border-top border-2 border-dark' />
                        </div>


                    </div>
                </div>

                {/* Relationship Dates */}
                <div>
                    <p className='paragraph fw-bold'>RELATIONSHIP DATES: </p>

                    <div className='row'>
                        <div className='col-6'>
                            <div className='mx-3 px-6 form-check'>
                                <CheckBox
                                    type={"checkbox"}
                                    value={formData?.relationshipDates?.marriedOn?.checked || false}
                                    checked={formData?.relationshipDates?.marriedOn?.checked === true}
                                    fillFormData={changeFormCheck}
                                    labelinput={"relationshipDates.marriedOn.checked"}
                                    checkbox={true}
                                    inline
                                />
                                <div className='data-input' style={{ justifyContent: "start" }}>
                                    <span className='label'> Married on (date){' '}</span>
                                    <input
                                        type='date'
                                        className='custom-input-control'
                                        value={formData?.relationshipDates?.marriedOn.date}
                                        onChange={fillFormData('relationshipDates.marriedOn.date')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='mx-3 px-6 form-check'>
                                <CheckBox
                                    type={"checkbox"}
                                    value={formData?.relationshipDates?.startedLivingTogetherOn?.checked || false}
                                    checked={formData?.relationshipDates?.startedLivingTogetherOn?.checked === true}
                                    fillFormData={changeFormCheck}
                                    labelinput={"relationshipDates.startedLivingTogetherOn.checked"}
                                    checkbox={true}
                                    inline
                                />
                                <div className='data-input' style={{ justifyContent: "start" }}>
                                    <span className='label'>   Started living together on (date){' '}</span>
                                    <input
                                        type='date'
                                        className='custom-input-control'
                                        value={
                                            formData?.relationshipDates?.startedLivingTogetherOn?.date
                                        }
                                        onChange={fillFormData('relationshipDates.startedLivingTogetherOn.date')}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-6 d-flex flex-row'>
                            <div className='mx-3 px-6 form-check'>
                                <CheckBox
                                    type={"checkbox"}
                                    value={formData?.relationshipDates?.marriedOn?.checked || false}
                                    checked={formData?.relationshipDates?.marriedOn?.checked === true}
                                    fillFormData={changeFormCheck}
                                    labelinput={"relationshipDates.marriedOn.checked"}
                                    checkbox={true}
                                    inline
                                />
                                <div className='data-input w-100' style={{ justifyContent: "start" }}>
                                    <label className='label' htmlFor='married_on'>
                                        Married on (date){' '}
                                    </label>
                                    <input
                                        type='date'
                                        className='custom-input-control'
                                        value={
                                            formData?.relationshipDates?.marriedOn.date
                                        }
                                        onChange={fillFormData(
                                            'relationshipDates.marriedOn.date'
                                        )}
                                    />
                                </div>
                            </div>


                        </div> */}
                        {/* <div className='col-6 d-flex flex-row'>
                            <div className='form-check'>
                                <input
                                    className='form-check-input small'
                                    type='checkbox'
                                    name='relationship_status'
                                    id='started_living_together'
                                    checked={
                                        formData?.relationshipDates?.startedLivingTogetherOn?.checked === true
                                    }
                                    onChange={changeFormCheck(
                                        'relationshipDates.startedLivingTogetherOn.checked'
                                    )}
                                />
                            </div>
                            <div className='data-input w-100'>
                                <label className='label' htmlFor='started_living_together'>
                                    Started living together on (date){' '}
                                </label>
                                <input
                                    type='date'
                                    className='custom-input-control'
                                    value={
                                        formData?.relationshipDates?.startedLivingTogetherOn?.date
                                    }
                                    onChange={fillFormData('relationshipDates.startedLivingTogetherOn.date')}
                                />
                            </div>
                        </div> */}
                    </div>

                    <div className='row'>
                        <div className='col-6'>
                            <div className='mx-3 px-6 form-check'>
                                <CheckBox
                                    type={"checkbox"}
                                    value={formData?.relationshipDates?.separatedOn?.checked || false}
                                    checked={formData?.relationshipDates?.separatedOn?.checked === true}
                                    fillFormData={changeFormCheck}
                                    labelinput={"relationshipDates.startedLivingTogetherOn.date"}
                                    checkbox={true}
                                    inline
                                />
                                <div className='data-input' style={{ justifyContent: "start" }}>
                                    <span className='label'>  Separated on (date){' '}</span>
                                    <input
                                        type='date'
                                        className='custom-input-control'
                                        value={
                                            formData?.relationshipDates?.separatedOn?.date
                                        }
                                        onChange={fillFormData(
                                            'relationshipDates.separatedOn.date'
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='mx-3 px-6 form-check'>
                                <CheckBox
                                    type={"checkbox"}
                                    value={formData?.relationshipDates?.isNeverLivedTogether?.checked || false}
                                    checked={formData?.relationshipDates?.isNeverLivedTogether?.checked === true}
                                    fillFormData={changeFormCheck}
                                    labelinput={"relationshipDates.isNeverLivedTogether.checked"}
                                    checkbox={true}
                                    inline
                                />
                                <div className='data-input' style={{ justifyContent: "start" }}>
                                    <label className='label' htmlFor='separated_on'>
                                        Never Lived Together
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Separator */}
                    <div className='row pb-10px ml-10px mt-30px border-top border-2 border-dark' />
                </div>

            </div>
        </>
    )
}

export default FamilyHistory