const otherBenefits = [
    { item: '', details: '', yearlyMarketValues: '' },
    { item: '', details: '', yearlyMarketValues: '' },
    { item: '', details: '', yearlyMarketValues: '' },
    { item: '', details: '', yearlyMarketValues: '' }
]

const specialChildExpenses = [
    { name: '', expenses: '', amount: '',tax: '',},
    { name: '', expenses: '', amount: '',tax: '',},
    { name: '', expenses: '', amount: '',tax: '',},
    { name: '', expenses: '', amount: '',tax: '',}
]

const emptyWitness = [
    { name: '', topic: '', time_estimate: ''},
    { name: '', topic: '', time_estimate: ''},
    { name: '', topic: '', time_estimate: ''},
    { name: '', topic: '', time_estimate: ''}
]

const emptyExpert = [
    { name: '', cv_on_file: '', expertise_area: '', qualification: '',time_estimate:'' },
    { name: '', cv_on_file: '', expertise_area: '', qualification: '',time_estimate:'' },
    { name: '', cv_on_file: '', expertise_area: '', qualification: '',time_estimate:'' },
    { name: '', cv_on_file: '', expertise_area: '', qualification: '',time_estimate:'' }
]

const emptySpecialExpenses = [
    { type: '', amount: ''},
    { type: '', amount: ''},
    { type: '', amount: ''},
    { type: '', amount: ''},
   
]

const nestedItems = [
    ['Current term:', 'Requested change:'],
    ['Current term:', 'Requested change:'],
    ['Current term:', 'Requested change:'],
    ['Current term:', 'Requested change:'],
    ['Current term:', 'Requested change:'],
    ['Current term:', 'Requested change:'],
    ['Current term:', 'Requested change:'],
];

const types = ['a', 'b', 'c'];

const emptyDocumentList = [
    { document_name: '', author: '', document_date: '' },
    { document_name: '', author: '', document_date: '' },
    { document_name: '', author: '', document_date: '' },
    { document_name: '', author: '', document_date: '' },
    { document_name: '', author: '', document_date: '' }
]


export {
    otherBenefits,
    specialChildExpenses,
    emptyWitness,
    emptyExpert,
    emptySpecialExpenses,
    nestedItems,
    types,
    emptyDocumentList
}