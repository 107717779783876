import React from 'react'
import BoldandThinText from '../../forms/shared/BoldandThinText'
import DynamicTextArea from '../../forms/shared/TextArea'
import CheckBox from '../../forms/shared/CheckBox'

const Facts = ({ formData, changeFormCheck, fillFormData }) => {
  return (
    <>
      <div className='row  mt-40px'>
        <p className='sub-heading'>
          IMPORTANT FACTS SUPPORTING THE CLAIM FOR DIVORCE
        </p>

        {/* Seperation */}

        <ul style={{ listStyleType: "none", padding: 0 }}>
          <li>
            <div className='d-flex flex-row gap-4 mt-20px' >
              <div className='form-check'>
                <CheckBox
                  type='checkbox'
                  value={formData?.importantFacts?.separation?.haveNotLivedTogether || false}
                  labelinput={'importantFacts.separation.haveNotLivedTogether'}
                  fillFormData={changeFormCheck}
                  isBold={false}
                  checkbox={true}
                  checked={formData?.importantFacts?.separation?.haveNotLivedTogether === true}
                  inline
                  noPadding
                />
                <span><b>Separation</b>: The spouses have lived separate and apart since (date)</span>
              </div>

              <div className='data-input' style={{ justifyContent: "start" }}>
                <input
                  type='date'
                  className='custom-input-control'
                  value={formData?.importantFacts?.separation?.date}
                  onChange={fillFormData('importantFacts.separation.date')}
                />
              </div>
              <span >and</span>
            </div>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              <li>
                <CheckBox
                  label={`have not lived together again since that date in an unsuccessful attempt at reconcile.`}
                  type='checkbox'
                  value={'not_lived_fact'}
                  labelinput={'importantFacts.separation.sub'}
                  fillFormData={fillFormData}
                  isBold={false}
                  checkbox={true}
                  checked={formData?.importantFacts?.separation?.sub === 'not_lived_fact'}
                />
              </li>
              <li>
                <CheckBox
                  label={`have lived toghether again during the following period(s) in an unsuccessful attempt at reconcile: (Give dates.)`}
                  type='checkbox'
                  value={'have_lived_fact'}
                  labelinput={'importantFacts.separation.sub'}
                  fillFormData={fillFormData}
                  isBold={false}
                  checkbox={true}
                  checked={formData?.importantFacts?.separation?.sub === 'have_lived_fact'}
                />
                <div className='data-input'>
                  <input
                    type='text'
                    className='custom-input-control'
                    value={
                      formData?.importantFacts?.separation?.sub?.details
                    }
                    onChange={fillFormData(
                      'importantFacts.separation.sub.details'
                    )}
                  />
                </div>
              </li>
            </ul>
          </li>
          {/* Adultery */}
          <li>
            <div class="data-input">
              <div className='form-check'>
                <CheckBox
                  type='checkbox'
                  value={formData?.importantFacts?.adultry?.checked || false}
                  labelinput={'importantFacts.adultry.checked'}
                  fillFormData={changeFormCheck}
                  isBold={false}
                  checkbox={true}
                  checked={formData?.importantFacts?.adultry?.checked === true}
                  inline
                  noPadding
                />
                <label className='form-check-label text-nowrap'>
                  <b>Adultery: </b>(Name of spouse)
                </label>
              </div>
              <div className='data-input w-100' style={{ justifyContent: "start" }}>
                <input
                  type='text'
                  className='custom-input-control'
                  value={formData?.importantFacts?.adultry?.nameOfSpouse}
                  onChange={fillFormData('importantFacts.adultry.nameOfSpouse')}
                />
              </div>
              <span className='text-nowrap'>has committed Adultery</span>
            </div>
            <p className='fst-italic small'>
              (Give details. It is not necessary to name any other person involved
              but if you do name the other person, then you must serve this
              application on that person.)
            </p>

            <DynamicTextArea
              rows={5}
              formData={formData}
              updates={'importantFacts.adultry.details'}
              fillFormData={fillFormData}
              value={formData?.importantFacts?.adultry?.details}
            />
          </li>
          {/* Cruelty */}
          <li>
            <div class="data-input">
              <div className='form-check'>
                <CheckBox
                  type='checkbox'
                  value={formData?.importantFacts?.cruelty?.checked || false}
                  labelinput={'importantFacts.cruelty.checked'}
                  fillFormData={changeFormCheck}
                  isBold={false}
                  checkbox={true}
                  checked={formData?.importantFacts?.cruelty?.checked === true}
                  inline
                  noPadding
                />

                <label
                  className='form-check-label text-nowrap'
                  htmlFor='cruelty_fact'
                >
                  <b>Cruelty: </b>
                  (Name of spouse)
                </label>
              </div>
              <div className='data-input w-100' style={{ justifyContent: "start" }}>
                <input
                  type='text'
                  className='custom-input-control'
                  value={formData?.importantFacts?.cruelty?.nameOfSpouse}
                  onChange={fillFormData('importantFacts.cruelty.nameOfSpouse')}
                />
              </div>
              <span className='text-nowrap'>has treated (name of spouse)</span>
              <div className='data-input w-100'>
                <input
                  type='text'
                  className='custom-input-control'
                  value={formData?.importantFacts?.cruelty?.hasTreatedNameOfSpouse}
                  onChange={fillFormData(
                    'importantFacts.cruelty.hasTreatedNameOfSpouse'
                  )}
                />
              </div>
            </div>


            <BoldandThinText thin={`with physical or mental cruelty of such a kind as to make continued
              cohabitation intolerable. (Give details.)`} />

            <DynamicTextArea
              rows={3}
              updates={'importantFacts.cruelty.details'}
              fillFormData={fillFormData}
              value={formData?.importantFacts?.cruelty?.details}
            />

          </li>
        </ul>
      </div>

    </>
  )
}

export default Facts