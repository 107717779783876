import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { CalcualteAnnualIncome } from '../../../../utils/Apis/matters/CustomHook/CalculateTotals';

const IncomeDeclaration = ({ formData, changeAmount, fillFormData, filler, changeFormCheck }) => {

    const AnnualIncome = CalcualteAnnualIncome(formData, filler)

    return (
        <>
            <li className='ol-m5'>
                <p className='paragrap'>I am currently</p>

                <div className='row gap-2 pb-10px'>
                    <div className='d-flex flex-row'>
                        <div className='form-check mr-1'>
                            <input
                                className='form-check-input'
                                type='radio'
                                name='employment'
                                value='employed'
                                id='employed_by'
                                checked={formData?.employmentStatus?.[filler]?.employmentStatus === 'employed'}
                                onChange={fillFormData(`employmentStatus.${filler}.employmentStatus`)}
                            />
                            <label
                                className='form-check-label text-nowrap'
                                htmlFor='employed'
                            >
                                employed by (name and address of employer)
                            </label>
                        </div>
                        <div className='data-input w-100 mt-0'>
                            <input
                                type='text'
                                className='custom-input-control p-0 border-bottom'
                                value={formData?.employmentStatus?.[filler]?.employerName}
                                onChange={fillFormData(`employmentStatus.${filler}.employerName`)}
                            />
                        </div>
                    </div>

                    <div className='d-flex flex-row'>
                        <div className='form-check mr-1'>
                            <input
                                className='form-check-input'
                                type='radio'
                                name='employment'
                                value='self_employed'
                                id='self_employed'
                                checked={
                                    formData?.employmentStatus?.[filler]?.employmentStatus === 'self_employed'
                                }
                                onChange={fillFormData(`employmentStatus.${filler}.employmentStatus`)}
                            />
                            <label
                                className='form-check-label text-nowrap'
                                htmlFor='self_employed'
                            >
                                self-employed, carrying on business under the name of (name
                                and address of business)
                            </label>
                        </div>
                        <div className='data-input w-100 mt-0'>
                            <input
                                type='text'
                                className='custom-input-control p-0 border-bottom'
                                value={formData?.employmentStatus[filler]?.businessName}
                                onChange={fillFormData(`employmentStatus.${filler}.businessName`)}
                            />
                        </div>
                    </div>

                    <div className='d-flex flex-row'>
                        <div className='form-check mr-1'>
                            <input
                                className='form-check-input'
                                type='radio'
                                name='employment'
                                value='unemployed'
                                id='unemployed'
                                checked={formData?.employmentStatus?.[filler]?.employmentStatus === 'unemployed'}
                                onChange={fillFormData(`employmentStatus.${filler}.employmentStatus`)}
                            />
                            <label
                                className='form-check-label text-nowrap'
                                htmlFor='unemployed'
                            >
                                unemployed since (date when last employed)
                            </label>
                        </div>
                        <div className='data-input w-100 mt-0'>
                            <input
                                type='text'
                                className='custom-input-control p-0 border-bottom'
                                value={formData?.unemployedDate}
                                onChange={fillFormData(`employmentStatus.${filler}.lastEmployed`)}
                            />
                        </div>
                    </div>
                </div>
            </li>
        </>
    );
};

export default IncomeDeclaration;
