import React from "react";
import Layout from "../../components/LayoutComponents/Layout";
import { useSelector } from "react-redux";
import TaskTypeFormTrustDeposit from "./TaskTypeFormTrustDeposit";

const CreateTrustDeposit = ({ type }) => {
  const { userInfo } = useSelector((state) => state.userLogin);
  
  return (
    <Layout title={`Welcome ${userInfo?.username ? userInfo?.username : ""}`}>
      <h5 className="calcTitle mb-0">Tasks / Add Trust Deposit Slip</h5>
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <TaskTypeFormTrustDeposit/>
        </div>
      </div>
    </Layout>
  );
};

export default CreateTrustDeposit;
