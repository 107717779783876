import React from 'react'

const FormHeading = ({ heading }) => {
    return (
        <>
            <div className='text-center mb-4'>
                <div className='fw-bold fst-italic'>{heading}</div>
            </div>

        </>
    )
}

export default FormHeading