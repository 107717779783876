import React from 'react'

const HeadingSeperator = ({text}) => {
  return (
    <>
     <div className='row pb-10px border-top border-2 border-dark' />
    </>
  )
}

export default HeadingSeperator